import { Country } from "@prisma/client"
import { Continent } from "../components/place-selector"

export function groupCountries(countryList: Country[]): Continent[] {
  const continentList: Continent[] = []

  countryList.forEach((country) => {
    let continent = continentList.find((continent) => {
      return continent.name === country.continent
    })

    if (!continent) {
      continent = {
        name: country.continent,
        countryList: [],
      }
      continentList.push(continent)
    }

    continent.countryList.push(country)
  })

  return continentList
}
