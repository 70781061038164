import { Listbox, Transition } from "@headlessui/react"
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline"
import { Fragment, useEffect, useState } from "react"
import { shallowEqual } from "../lib/shallow-equal"

interface Option<ValueType> {
  key: string
  value: ValueType
  label: string | React.ReactNode
}

interface SelectProps<ValueType> {
  value?: ValueType
  optionList: Option<ValueType>[]
  onChange?: (value: ValueType) => void
  placeholder?: string | React.ReactNode
}

function getOptionFromValue<ValueType>(
  optionList: Option<ValueType>[],
  value?: ValueType,
) {
  return optionList.find((option) => shallowEqual(option.value, value))
}

export const Select = <ValueType extends {}>({
  value,
  optionList,
  onChange,
  placeholder = "",
}: SelectProps<ValueType>) => {
  const [selectedOption, setSelectedOption] = useState<
    Option<ValueType> | undefined
  >(getOptionFromValue<ValueType>(optionList, value))

  useEffect(() => {
    setSelectedOption(getOptionFromValue(optionList, value))
  }, [value, optionList])

  return (
    <div className="w-full text-gray-700">
      <Listbox
        value={value}
        onChange={(value: ValueType) => {
          onChange && onChange(value)
        }}
      >
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-200 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">
              {selectedOption ? (
                selectedOption.label
              ) : (
                <span className="text-gray-500">{placeholder}</span>
              )}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {optionList.map((option) => {
                return (
                  <Listbox.Option
                    key={option.key}
                    className={({ active }) =>
                      `${active ? "text-blue-900 bg-blue-100" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate`}
                        >
                          {option.label}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? "text-blue-600" : "text-blue-600"
                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                )
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
