import { State } from "@prisma/client"
import axios from "axios"

export async function loadCountryStates(
  countryCode: string,
  withLocations = false,
) {
  const { data } = await axios.get<State[]>(
    `/api/states?countryCode=${countryCode}&withLocations=${
      withLocations ? 1 : 0
    }`,
  )

  return data
}
