import { Country } from "@prisma/client"
import type { GetServerSideProps, NextPage } from "next"
import { useRouter } from "next/dist/client/router"
import Head from "next/head"
import { useEffect } from "react"
import { useContinentList, useCountryList } from "../lib/app-context"
import { prisma } from "../lib/prisma-client"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { groupCountries } from "../lib/countries"
import { PlaceSelector } from "../components/place-selector"
import { useTranslation } from "next-i18next"

const Home: NextPage<{
  countryList: Country[]
}> = ({ countryList }) => {
  const router = useRouter()
  const { setCountryList } = useCountryList()
  const { setContinentList } = useContinentList()
  const { t } = useTranslation("frontend")

  useEffect(() => {
    setCountryList && setCountryList(countryList)
    const continentList = groupCountries(countryList)
    setContinentList && setContinentList(continentList)
  }, [])

  return (
    <div className="w-full h-full bg-gray-100 flex flex-row">
      <Head>
        <title>Distributors</title>
        <meta name="description" content="POI Map" />
      </Head>

      <main className="flex flex-col flex-grow h-full items-center justify-center">
        <div className="h-full w-full flex justify-end relative">
          <div className="absolute inset-0 w-full h-full z-0 hidden lg:block lg:overflow-hidden">
            <video
              className="min-w-full min-h-full object-cover"
              autoPlay
              playsInline
              muted
              loop
            >
              <source src="bg-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-full lg:w-1/2 xl:pr-[10%] p-10 h-full bg-white bg-opacity-70 flex flex-col justify-center relative z-10">
            <h1 className="text-6xl font-semibold text-blue-900">
              {t("title")}
            </h1>
            <div className="py-6">{t("header")}</div>
            <div className="w-72">
              <PlaceSelector
                onCountrySelect={(country) => {
                  router.push(`/locations/${country.code}`)
                }}
                onStateSelect={(country, state) => {
                  router.push(`/locations/${country.code}/state/${state.code}`)
                }}
                showPlacesWithLocationsOnly={true}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const countryList = await prisma.country.findMany({
    where: { locations: { some: {} } },
    orderBy: [{ continent: "asc" }, { name: "asc" }],
  })

  return {
    props: {
      countryList,
      ...(await serverSideTranslations(locale as string, [
        "frontend",
        "countries",
      ])),
    },
  }
}
