import { Country, State } from "@prisma/client"
import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { useContinentList, useStateList } from "../lib/app-context"
import { loadCountryStates } from "../lib/states"
import { Select } from "./select"

export type Continent = {
  name: string
  countryList: Country[]
}

export const PlaceSelector: React.FC<{
  orientation?: "horizontal" | "vertical"
  continent?: Continent
  country?: Country
  state?: State
  onCountrySelect?: (country: Country) => void
  onStateSelect?: (country: Country, state: State) => void
  showPlacesWithLocationsOnly?: boolean
}> = ({
  orientation = "vertical",
  continent,
  country,
  state,
  onCountrySelect,
  onStateSelect,
  showPlacesWithLocationsOnly = false,
}) => {
  const [selectedContinent, setSelectedContinent] = useState(continent)
  const [selectedCountry, setSelectedCountry] = useState(country)
  const [selectedState, setSelectedState] = useState(state)

  const { continentList } = useContinentList()
  const { stateList, setStateList } = useStateList()
  const { t } = useTranslation("frontend")
  const { t: ct } = useTranslation("countries")
  const isHorizontal = orientation === "horizontal"

  useEffect(() => {
    continent && setSelectedContinent(continent)
    setSelectedCountry(undefined)
    setSelectedState(undefined)
  }, [continent])

  useEffect(() => {
    if (country) {
      setSelectedCountry(country)

      //Check for continent
      const newContinent = continentList.find(
        (continent) => country.continent === continent.name,
      )
      if (newContinent != selectedContinent) {
        setSelectedContinent(newContinent)
      }
    }
  }, [country, continentList])

  useEffect(() => {
    if (state) {
      setSelectedState(state)
    }
  }, [state])

  useEffect(() => {
    if (selectedCountry?.withStates) {
      ;(async () => {
        const stateList = await loadCountryStates(
          selectedCountry.code,
          showPlacesWithLocationsOnly,
        )
        stateList && setStateList && setStateList(stateList)
      })()
    }
  }, [selectedCountry])

  return (
    <div
      className={`w-full flex ${
        isHorizontal ? "flex-rows space-x-2" : "flex-col space-y-2"
      } `}
    >
      <div className={`${isHorizontal ? "w-72" : "w-full"}`}>
        <Select
          placeholder={t("select-continent")}
          optionList={continentList.map((continent) => ({
            key: continent.name,
            value: continent,
            label: t(continent.name),
          }))}
          value={selectedContinent}
          onChange={(continent) => {
            setSelectedContinent(continent)
            setSelectedCountry(undefined)
            setSelectedState(undefined)
          }}
        />
      </div>

      {selectedContinent && (
        <div className={`${isHorizontal ? "w-72" : "w-full"}`}>
          <Select
            placeholder={t("select-country")}
            optionList={selectedContinent.countryList
              .sort((a, b) => ct(a.name).localeCompare(ct(b.name)))
              .map((country) => ({
                key: country.code,
                value: country,
                label: ct(country.name),
              }))}
            value={selectedCountry}
            onChange={(country) => {
              setSelectedCountry(country)
              setSelectedState(undefined)
            }}
          />
        </div>
      )}

      {selectedCountry && selectedCountry.withStates && stateList && (
        <div className={`${isHorizontal ? "w-72" : "w-full"}`}>
          <Select
            placeholder={t("select-state")}
            optionList={[
              {
                key: "--all",
                value: {
                  code: "--all",
                  countryCode: "--all",
                  name: t("all-states"),
                },
                label: t("all-states"),
              },
              ...stateList
                .sort((a, b) => ct(a.name).localeCompare(ct(b.name)))
                .map((state) => ({
                  key: state.code,
                  value: state,
                  label: ct(state.name),
                })),
            ]}
            value={selectedState}
            onChange={(state) => {
              setSelectedState(state)
            }}
          />
        </div>
      )}

      {selectedCountry && (
        <div className={`${isHorizontal ? "w-72" : "w-full"} pt-2`}>
          <button
            className="w-full text-center py-2 bg-ars-red-600 text-white rounded-lg shadow-md"
            onClick={() => {
              if (
                selectedCountry &&
                selectedState &&
                selectedState.code != "--all"
              ) {
                onStateSelect && onStateSelect(selectedCountry, selectedState)
              } else if (selectedCountry) {
                onCountrySelect && onCountrySelect(selectedCountry)
              }
            }}
          >
            {t("find-distributor")}
          </button>
        </div>
      )}
    </div>
  )
}
